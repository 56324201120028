import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Seo from '../components/seo';
import aboutImg from '../images/about.jpg';
import handImg from '../images/hand.svg';
import aboutTitle from '../images/about-title.svg';
import ContactButtons from '../components/homepage/contactButtons';

const AboutPage = styled.section`
    min-height: 100vh;

    .about-image {
        display: flex;
        justify-content: flex-end;
        @media (min-width: 1200px) {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
        }
        img {
            width: 70%;
            align-self: center;
            @media (min-width: 1200px) {
                width: auto;
                height: 100%;
            }
            @media (min-width: 1500px) {
                width: auto;
                height: 100%;
            }
        }
    }

    .about-title {
        color: white;
        font-family: ${props => props.theme.titleFont};
        line-height: 1.1;
        font-size: 3rem;
        font-weight: 500;

        @media (min-width: 450px) {
            font-size: 4rem;
        }
        @media (min-width: 650px) {
            font-size: 5rem;
        }
        @media (min-width: 768px) {
            font-size: 6rem;
        }
        @media (min-width: 1200px) {
            display: none;
        }
    }

    .about-text {
        color: white;

        @media (min-width: 1600px) {
            font-size: 1.1rem;
        }
    }

    .about-wrap {
        padding: 0 30px 55px 30px;
        position: relative;
        margin-top: -100px;
        height: 100%;

        @media (min-width: 550px) {
            padding: 0 50px 50px 50px;
        }

        @media (min-width: 768px) {
            padding: 0 0 50px 35px;
            margin-top: -220px;
        }

        @media (min-width: 1200px) {
            margin-top: 0;
            padding: 100px 0 50px 50px;
            display: flex;
            align-items: center;
            min-height: 100vh;
        }
    }

    .about-contact {
        padding-top: 30px;
    }

    .about-imgtext {
        display: none;
        transition: all 400ms;
        transition-delay: 200ms;
        opacity: ${props => (props.pageIn ? '1' : '0')};
        transform: translateX(${props => (props.pageIn ? '0px' : '-50px')});
        @media (min-width: 1200px) {
            display: block;
            margin-right: 100px;

            height: 70vh;
            max-height: 500px;
        }

        @media (min-width: 1400px) {
            max-height: 600px;
        }

        @media (min-width: 1600px) {
            max-height: 800px;
            margin-right: 120px;
        }

        @media (min-width: 1800px) {
            margin-right: 170px;
        }
    }

    .about-inner {
        margin-top: 30px;
        transition: all 400ms;
        transition-delay: 250ms;
        opacity: ${props => (props.pageIn ? '1' : '0')};
        transform: translateX(${props => (props.pageIn ? '0px' : '-50px')});

        @media (min-width: 768px) {
            max-width: 500px;
        }

        @media (min-width: 1200px) {
            margin: 0;
        }

        @media (min-width: 1800px) {
            max-width: 600px;
        }
    }

    .about-buttons {
        margin-top: 25px;
        @media (min-width: 1600px) {
            font-size: 1.1rem;
        }
    }
`;

const ContactBlock = styled.div`
    color: white;
    font-weight: 700;
    display: flex;
    align-items: center;
    @media (min-width: 1600px) {
        font-size: 1.1rem;
    }

    img {
        margin-left: 10px;
        transform: translateY(7px);
    }
`;

class About extends Component {
    state = {
        pageIn: false,
    };

    componentDidMount() {
        this.setState({
            pageIn: true,
        });
    }

    render() {
        const { data } = this.props;

        return (
            <>
                <Seo title="About me" />
                <AboutPage pageIn={this.state.pageIn}>
                    {data.wordpressPage.acf.profile_image && (
                        <div className="about-image">
                            <img
                                src={data.wordpressPage.acf.profile_image.source_url}
                                alt={data.wordpressPage.acf.profile_image.alt_text}
                            />
                        </div>
                    )}
                    <div className="about-wrap">
                        <h1 className="about-title">
                            A little
                            <br />
                            about me
                        </h1>

                        <img src={aboutTitle} alt="About me" className="about-imgtext" />

                        <div className="about-inner">
                            {data.wordpressPage.acf.page_text_content && (
                                <div
                                    className="about-text"
                                    dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.page_text_content }}
                                />
                            )}

                            <div className="about-contact">
                                <ContactBlock>
                                    <p className="contactblock-title">
                                        Say hi!
                                        <img src={handImg} className="contactblock-hand" alt="Say hi!" />
                                    </p>
                                </ContactBlock>
                                <div className="about-buttons">
                                    <ContactButtons
                                        options={data.allWordpressAcfOptions.edges[0].node.options}
                                        stacked
                                        withText
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </AboutPage>
            </>
        );
    }
}

export const query = graphql`
    query {
        wordpressPage(wordpress_id: { eq: 36 }) {
            title
            acf {
                page_text_content
                profile_image {
                    source_url
                    alt_text
                }
            }
        }
        allWordpressAcfOptions {
            edges {
                node {
                    options {
                        instagram_link
                        instagram_handle
                        email_address
                    }
                }
            }
        }
    }
`;

export default About;
